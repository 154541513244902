#applicationState {
  text-align: center;
  font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#applicationState td,
#applicationState th {
  border: 1px solid #ddd;
  padding: 8px;
}

#applicationState tr:nth-child(even) {
  background-color: #f2f2f2;
}

#applicationState tr:hover {
  background-color: #ddd;
}

#applicationState th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #2675a0;
  color: white;
  font-weight: normal;
  font-size: 1.25rem;
}

/* Change font size */
html {
  font-size: 18px;
}

.List {
  border: 1px solid #d9dddd;
  border-top: 0px;
  background-color: black;
  color: white;
}

.ListItemEven,
.ListItemOdd {
  align-items: center;
  justify-content: left;
  padding: 15px;
  background-color: black;
}

.ListItemEven {
  background-color: black;
}

.dynamic-window {
  height: 800px;
}

.custom-btn-log {
  background-color: #2675a0 !important;
  border: #fff !important;
}
