.custom-div {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.search-text {
  font-size: 1.5rem;
  margin-right: 12px;
  margin-bottom: 0px;
  padding: 0.25rem;
}

.custom-input {
  border-width: 2px;
  border-style: solid;
  border-color: rgb(16 185 129);
  outline: none;
  border-radius: 0.5rem;
  padding: 0.25rem;
}

.custom-btn {
  background-color: #2675a0 !important;
  border: #fff !important;
  width: 100px;
}
